import Link from 'next/link';
import {
  Album,
  Artist,
  Epoch,
  Favorite,
  Genre,
  Group,
  LiveConcert,
  Partner,
  PerformanceWork,
  PictureType,
  PlaybackProgress,
  Role,
  Video,
  VodConcert,
  Work,
} from 'generated/graphql';
import { Badge } from 'src/components/common/badge';
import { LiveConcertBadge } from 'src/components/live-concert/live-concert-badge';
import { LiveConcertTime } from 'src/components/live-concert/live-concert-time';
import MediaDuration from 'src/components/media-duration';
import { NodeFreeBadge } from 'src/components/node/node-free-badge';
import { Teaser } from 'src/components/teaser/teaser';
import { TeaserImage } from 'src/components/teaser/teaser-image';
import { TeaserMeta } from 'src/components/teaser/teaser-meta';
import { TeaserProgress } from 'src/components/teaser/teaser-progress';
import { TeaserTitle } from 'src/components/teaser/teaser-title';
import useIntl from 'src/hooks/use-intl';
import { getPictureByType } from 'src/utilities/image-helpers';
import { getNodePath } from 'src/utilities/url-helpers';

type NodeTeaserProps = {
  node:
    | Pick<Album, '__typename' | 'id' | 'title' | 'artistAndGroupDisplayInfo' | 'pictures'>
    | (Pick<Artist, '__typename' | 'id' | 'name' | 'pictures' | 'roles'> & { roles: Pick<Role, 'displayName'>[] })
    | Pick<Epoch, '__typename' | 'id' | 'title' | 'pictures' | 'endYear' | 'startYear'>
    | (Pick<Favorite, '__typename' | 'id'> & {
        content:
          | Pick<
              Extract<Favorite['content'], { __typename: 'Album' }>,
              '__typename' | 'id' | 'title' | 'artistAndGroupDisplayInfo' | 'pictures'
            >
          | (Pick<Extract<Favorite['content'], { __typename: 'PerformanceWork' }>, '__typename' | 'id'> & {
              vodConcert: Pick<
                VodConcert,
                'id' | '__typename' | 'pictures' | 'title' | 'totalDuration' | 'typeDisplayName'
              >;
              work: Pick<Work, 'title'>;
            })
          | Pick<
              Extract<Favorite['content'], { __typename: 'Video' }>,
              | '__typename'
              | 'id'
              | 'duration'
              | 'title'
              | 'typeDisplayName'
              | 'pictures'
              | 'publicationLevel'
              | 'subtitle'
            >;
      })
    | Pick<Genre, '__typename' | 'id' | 'title' | 'pictures'>
    | Pick<Group, '__typename' | 'id' | 'name' | 'typeDisplayName' | 'pictures'>
    | (Pick<
        LiveConcert,
        | '__typename'
        | 'id'
        | 'finalEndTime'
        | 'title'
        | 'subtitle'
        | 'pictures'
        | 'publicationLevel'
        | 'type'
        | 'typeDisplayName'
        | 'endTime'
        | 'startTime'
        | 'streamStartTime'
        | 'reruns'
      > & {
        stream?: LiveConcert['stream'] | undefined;
      })
    | Pick<Partner, '__typename' | 'id' | 'name' | 'pictures'>
    | (Pick<PerformanceWork, '__typename' | 'id'> & {
        vodConcert: Pick<VodConcert, 'id' | '__typename' | 'pictures' | 'title' | 'totalDuration' | 'typeDisplayName'>;
        work: Pick<Work, 'title'>;
      })
    | (Pick<PlaybackProgress, '__typename' | 'id' | 'mark'> & {
        content:
          | (Pick<Extract<Favorite['content'], { __typename: 'PerformanceWork' }>, '__typename' | 'id' | 'duration'> & {
              vodConcert: Pick<
                VodConcert,
                'id' | '__typename' | 'pictures' | 'title' | 'totalDuration' | 'typeDisplayName'
              >;
              work: Pick<Work, 'title'>;
            })
          | (Pick<Extract<PlaybackProgress['content'], { __typename: 'Track' }>, '__typename' | 'id'> & {
              album: Pick<Album, '__typename' | 'id' | 'title' | 'artistAndGroupDisplayInfo' | 'pictures'>;
            })
          | Pick<
              Extract<Favorite['content'], { __typename: 'Video' }>,
              | '__typename'
              | 'id'
              | 'duration'
              | 'title'
              | 'typeDisplayName'
              | 'pictures'
              | 'publicationLevel'
              | 'subtitle'
            >;
      })
    | Pick<
        Video,
        '__typename' | 'id' | 'duration' | 'title' | 'typeDisplayName' | 'pictures' | 'publicationLevel' | 'subtitle'
      >
    | Pick<
        VodConcert,
        | '__typename'
        | 'id'
        | 'totalDuration'
        | 'title'
        | 'subtitle'
        | 'typeDisplayName'
        | 'pictures'
        | 'publicationLevel'
      >;
};

/**
 * Takes a given node and renders a teaser for it (e.g. An album node will render an album teaser)
 * It is used in sliders and grid views.
 */
export function NodeTeaser({ node }: NodeTeaserProps) {
  const { listFormat } = useIntl();

  // Favorite and PlaybackProgress nodes have a content property that contains the actual node (e.g. Album, Video, etc.)
  const teaserNode = node.__typename === 'Favorite' || node.__typename === 'PlaybackProgress' ? node.content : node;

  return (
    <Link
      href={getNodePath(teaserNode)}
      prefetch={false}
      className="block rounded-sm outline-offset-2 focus-visible:focus-outline"
      data-test={`node-teaser:${teaserNode.id}`}
    >
      {/* Album Teaser */}
      {teaserNode.__typename === 'Album' && (
        <Teaser>
          <TeaserImage
            src={getPictureByType(teaserNode.pictures, PictureType.Cover)?.url}
            alt={teaserNode.title}
            variant="square"
            playable
          />
          <TeaserTitle primary={teaserNode.title} secondary={teaserNode.artistAndGroupDisplayInfo} />
        </Teaser>
      )}
      {/* Artist Teaser */}
      {teaserNode.__typename === 'Artist' && (
        <Teaser>
          <TeaserImage
            src={getPictureByType(teaserNode.pictures, PictureType.TeaserSquare)?.url}
            alt={teaserNode.name}
            placeholder="artist"
            variant="round"
            darken
          />
          <TeaserTitle
            primary={teaserNode.name}
            secondary={
              teaserNode.roles.length > 0
                ? listFormat(teaserNode.roles.map(({ displayName }) => displayName))
                : undefined
            }
            center
          />
        </Teaser>
      )}
      {/* Epoch Teaser */}
      {teaserNode.__typename === 'Epoch' && (
        <Teaser>
          <TeaserImage
            src={getPictureByType(teaserNode.pictures, PictureType.Teaser)?.url}
            alt={teaserNode.title}
            variant="wide"
          >
            <div className="absolute inset-0 flex flex-col items-center justify-center bg-mainBgBlueC2/60">
              <div className="dg-text-medium-3 text-brandYellowC1">{teaserNode.title}</div>
              <div
                className="dg-text-regular-4 text-white"
                dangerouslySetInnerHTML={{
                  // Add zero-width non-joiner in case startYear or endYear is missing to align the text between different epoch teasers
                  __html:
                    teaserNode.startYear && teaserNode.endYear
                      ? `${teaserNode.startYear} &ndash; ${teaserNode.endYear}`
                      : '&zwnj;',
                }}
              />
            </div>
          </TeaserImage>
        </Teaser>
      )}
      {/* Genre Teaser */}
      {teaserNode.__typename === 'Genre' && (
        <Teaser>
          <TeaserImage
            src={getPictureByType(teaserNode.pictures, PictureType.Teaser)?.url}
            alt={teaserNode.title}
            variant="wide"
          >
            <div className="absolute inset-0 flex flex-col items-center justify-center bg-mainBgBlueC2/60">
              <div className="dg-text-medium-3 text-brandYellowC1">{teaserNode.title}</div>
            </div>
          </TeaserImage>
        </Teaser>
      )}
      {/* Group Teaser */}
      {teaserNode.__typename === 'Group' && (
        <Teaser>
          <TeaserImage
            src={getPictureByType(teaserNode.pictures, PictureType.TeaserSquare)?.url}
            alt={teaserNode.name}
            placeholder="group"
            variant="round"
            darken
          />
          <TeaserTitle primary={teaserNode.name} secondary={teaserNode.typeDisplayName} center />
        </Teaser>
      )}
      {/* LiveConcert Teaser */}
      {teaserNode.__typename === 'LiveConcert' && (
        <Teaser>
          <TeaserImage
            src={getPictureByType(teaserNode.pictures, PictureType.Teaser)?.url}
            alt={teaserNode.title}
            variant="wide"
            playable
            darken
          />
          <div className="absolute left-0 top-0 p-2 lg:p-4">
            <NodeFreeBadge node={teaserNode} />
          </div>
          <TeaserTitle primary={teaserNode.title} secondary={teaserNode.subtitle} />
          <TeaserMeta>
            <LiveConcertBadge liveConcert={teaserNode} small />
            <LiveConcertTime liveConcert={teaserNode} />
          </TeaserMeta>
        </Teaser>
      )}
      {/* Partner Teaser */}
      {teaserNode.__typename === 'Partner' && (
        <Teaser>
          <TeaserImage
            src={getPictureByType(teaserNode.pictures, PictureType.Logo)?.url}
            alt={teaserNode.name}
            placeholder="partner"
            variant="round"
          />
          <TeaserTitle primary={teaserNode.name} center />
        </Teaser>
      )}
      {/* PerformanceWork Teaser */}
      {teaserNode.__typename === 'PerformanceWork' && (
        <Teaser>
          <TeaserImage
            src={getPictureByType(teaserNode.vodConcert.pictures, PictureType.Teaser)?.url}
            alt={teaserNode.vodConcert.title}
            variant="wide"
            playable
            darken
          />
          {node.__typename === 'PlaybackProgress' && node.content.__typename === 'PerformanceWork' && (
            <TeaserProgress value={node.mark / node.content.duration} />
          )}
          <TeaserTitle primary={teaserNode.work.title} secondary={teaserNode.vodConcert.title} />
          <TeaserMeta>
            <MediaDuration duration={teaserNode.vodConcert.totalDuration} />
            <Badge color="secondary" small>
              {teaserNode.vodConcert.typeDisplayName}
            </Badge>
          </TeaserMeta>
        </Teaser>
      )}
      {/* Track Teaser */}
      {teaserNode.__typename === 'Track' && (
        <Teaser>
          <TeaserImage
            src={getPictureByType(teaserNode.album.pictures, PictureType.Cover)?.url}
            alt={teaserNode.album.title}
            variant="square"
            playable
          />
          <TeaserTitle primary={teaserNode.album.title} secondary={teaserNode.album.artistAndGroupDisplayInfo} />
        </Teaser>
      )}
      {/* Video Teaser */}
      {teaserNode.__typename === 'Video' && (
        <Teaser>
          <TeaserImage
            src={getPictureByType(teaserNode.pictures, PictureType.Teaser)?.url}
            alt={teaserNode.title}
            variant="wide"
            playable
            darken
          />
          <div className="absolute left-0 top-0 p-2 lg:p-4">
            <NodeFreeBadge node={teaserNode} />
          </div>
          {node.__typename === 'PlaybackProgress' && node.content.__typename === 'Video' && (
            <TeaserProgress value={node.mark / node.content.duration} />
          )}
          <TeaserTitle primary={teaserNode.title} secondary={teaserNode.subtitle} />
          <TeaserMeta>
            <Badge color="secondary" small>
              {teaserNode.typeDisplayName}
            </Badge>
            <MediaDuration duration={teaserNode.duration} />
          </TeaserMeta>
        </Teaser>
      )}
      {/* VodConcert Teaser */}
      {teaserNode.__typename === 'VodConcert' && (
        <Teaser>
          <TeaserImage
            src={getPictureByType(teaserNode.pictures, PictureType.Teaser)?.url}
            alt={teaserNode.title}
            variant="wide"
            playable
            darken
          />
          <div className="absolute left-0 top-0 p-2 lg:p-4">
            <NodeFreeBadge node={teaserNode} />
          </div>
          <TeaserTitle primary={teaserNode.title} secondary={teaserNode.subtitle} />
          <TeaserMeta>
            <Badge color="secondary" small>
              {teaserNode.typeDisplayName}
            </Badge>
            <MediaDuration duration={teaserNode.totalDuration} />
          </TeaserMeta>
        </Teaser>
      )}
    </Link>
  );
}
